<div class="main">

  <canvas #canvas></canvas>

  <div class="svg">

    <svg #svg viewBox="0 0 1090 1500">

      <g>

        <polyline
          points="163 842 121 791 100 772 79 751 68 757 89 885 95 778 100 772"
        />

        <polyline points="154 831 131 846 113 872 113 916 142 953 169 949" />

        <polyline
          points="933 1039 951 1039 975 1026 988 990 988 945 1003 932 1011 848 1032 717 1011 702 975 801 964 846 973 870 969 903 946 945 930 1037 922 1100 899 1168 836 1270 778 1351 710 1411 613 1443 487 1443 414 1411 338 1338 265 1252 218 1186 184 1076 168 937 155 919 155 882 163 869 163 812 110 741 68 728 58 757 74 877 95 922 134 1018 150 1066 184 1076"
        />

        <polyline points="973 809 999 836 991 914 970 927 957 927" />

        <polygon
          points="427 1102 414 1118 430 1128 490 1178 527 1204 637 1204 705 1160 734 1126 752 1115 744 1097 710 1097 629 1084 592 1094 553 1081 493 1084 427 1102"
        />

        <polygon
          points="432 1110 482 1134 540 1147 603 1147 650 1136 700 1126 734 1105 689 1102 637 1100 590 1100 540 1097 493 1097 432 1110"
        />

        <line x1="702" y1="1103" x2="705" y2="1122" />

        <line x1="673" y1="1102" x2="678" y2="1130" />

        <polyline points="639 1100 642 1131 632 1140" />

        <polyline points="590 1101 590 1136 598 1147" />

        <line x1="578" y1="1147" x2="590" y2="1136" />

        <polyline points="537 1097 537 1136 544 1147" />

        <line x1="503" y1="1097" x2="503" y2="1138" />

        <line x1="469" y1="1102" x2="469" y2="1127" />

        <polyline
          points="503 969 537 974 550 969 545 958 516 958 503 969 490 963 480 935 480 890 503 864"
        />

        <polyline
          points="650 979 621 977 616 969 637 961 650 966 650 979 655 982 673 979 692 948 689 898 668 874"
        />

        <polygon
          points="312 725 351 736 383 738 432 728 469 725 472 720 425 688 385 681 346 691 312 725"
        />

        <polyline points="294 722 315 691 346 665 398 660 438 673 487 709" />

        <polyline
          points="409 685 417 699 414 715 404 728 390 733 372 736 354 730 346 717 343 704 350 690"
        />

        <polygon
          points="673 725 673 733 707 730 741 741 781 746 836 728 817 707 789 691 752 688 715 702 673 725"
        />

        <polyline
          points="854 722 838 702 807 683 770 675 728 675 697 686 679 704"
        />

        <polyline
          points="735 695 734 709 739 722 749 733 762 738 778 738 791 730 799 717 802 707 799 696"
        />

        <polyline points="498 670 519 694 524 728 498 759 464 796 417 825" />

        <polyline points="650 681 637 707 650 746 679 780 718 809" />

        <polyline points="875 738 865 704 841 686" />

        <polyline points="317 670 288 696 273 722" />

        <line x1="472" y1="607" x2="461" y2="568" />

        <line x1="456" y1="605" x2="427" y2="555" />

        <line x1="427" y1="594" x2="390" y2="547" />

        <line x1="393" y1="578" x2="351" y2="547" />

        <line x1="362" y1="576" x2="317" y2="563" />

        <line x1="325" y1="581" x2="291" y2="578" />

        <line x1="301" y1="594" x2="267" y2="599" />

        <line x1="286" y1="610" x2="244" y2="628" />

        <line x1="658" y1="636" x2="663" y2="599" />

        <line x1="684" y1="631" x2="713" y2="592" />

        <line x1="723" y1="623" x2="781" y2="579" />

        <line x1="762" y1="618" x2="832" y2="586" />

        <line x1="802" y1="620" x2="867" y2="607" />

        <line x1="828" y1="631" x2="889" y2="631" />

        <polyline points="205 489 155 599 144 759" />

        <polyline points="524 272 343 256 233 330 176 403" />

        <polyline points="893 330 933 288 893 230 810 199" />

        <polyline points="786 235 705 167 584 125" />

        <polyline points="689 220 595 172 461 172" />

        <polyline points="563 246 480 199 343 220" />

        <polyline points="778 180 702 125 524 83" />

        <polyline points="540 146 417 125 294 180" />

        <polyline points="273 222 176 311 136 403" />

        <polyline points="155 434 115 547 121 707" />

        <polyline points="487 102 370 102 273 154 233 220 184 264" />

        <polyline points="891 212 786 112 645 57 427 73" />

        <polyline points="948 290 956 256 870 154" />

        <polyline points="961 314 1022 353 1037 489" />

        <polyline points="933 351 985 384 1030 555" />

        <polyline points="914 427 982 576 998 696" />

        <polyline points="964 471 1024 586 1022 688" />

        <polyline points="870 306 841 264 786 220" />

        <polyline points="883 290 870 246 828 235" />

        <polyline points="913 385 955 412 976 443" />

        <line x1="741" y1="246" x2="679" y2="183" />

        <line x1="616" y1="246" x2="550" y2="199" />

        <polyline points="317 314 233 392 176 471" />

        <polyline points="933 521 964 641 975 759" />

        <polyline points="212 212 144 272 84 358 58 453" />

        <polyline points="121 364 53 508 53 594 68 715" />

        <line x1="95" y1="495" x2="81" y2="597" />

        <line x1="459" y1="711" x2="452" y2="726" />

        <line x1="697" y1="731" x2="701" y2="709" />

        <line x1="1031" y1="722" x2="980" y2="786" />

        <line x1="999" y1="762" x2="1012" y2="841" />

        <polyline points="733 754 772 768 791 768 828 759" />

        <polyline points="414 751 383 765 351 765" />

        <circle cx="380" cy="704" r="10" />

        <circle cx="766" cy="706" r="10" />

      </g>

      <g>

        <polyline points="409 482 229 482 319 482 319 744" />

        <line x1="462" y1="482" x2="462" y2="613" />

        <polyline points="624 482 624 678 594 725 542 744 489 726 466 678" />

        <polyline
          points="835 527 807 498 757 482 704 498 677 532 677 569 707 596 765 609 811 624 835 662 835 696 808 726 756 744 699 722 677 688"
        />

        <polyline points="192 1018 192 815 261 888 327 815 327 1018" />

        <polygon
          points="453 815 403 815 369 855 355 917 369 982 403 1018 453 1018 484 982 501 919 491 855 453 815"
        />

        <polyline
          points="638 1018 586 911 529 911 586 911 632 897 644 868 633 835 584 815 529 815 529 1018"
        />

        <polyline points="771 1018 672 1018 672 815 771 815" />

        <line x1="672" y1="917" x2="735" y2="917" />

        <polyline points="898 1018 799 1018 799 815 898 815" />

        <line x1="799" y1="917" x2="862" y2="917" />

      </g>

    </svg>

  </div>

</div>

